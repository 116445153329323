@import '../../globalVariables';

.PinDetailsModal {
  display        : grid;
  justify-items  : center;
  padding-bottom : 15px;
  width          : 80vw;
  @media (min-width : 676px) {
    width : 650px;
  }
}

.Status {
  align-items     : center;
  display         : flex;
  gap             : 20px;
  justify-content : center;
}

.FridgeStatus {
  align-content   : center;
  background      : #abf5d1;
  border          : 1px solid darken($ACTIVE_COLOR, 25%);
  border-radius   : 12px;
  display         : grid;
  font-size       : 12px;
  height          : 24px;
  justify-content : center;

  text-transform  : capitalize;
  width           : 70px;

  &:global(.INACTIVE) {
    background : $INACTIVE_COLOR;
    border     : 1px solid darken($INACTIVE_COLOR, 15%);
  }

  &:global(.UNKNOWN) {
    background : lightgrey;
    border     : 1px solid darken(lightgrey, 15%);
  }
}

.FillStatus {
  align-items : center;
  display     : flex;
  gap         : 5px;
}

.FillStatusDots {
  color   : #828e9e;
  display : flex;
  gap     : 2px;

  .FillStatusDot {
    background-color : lightgray;
    border           : 1px solid darken(lightgray, 15%);
    border-radius    : 50%;
    display          : inline-block;
    height           : 10px;
    width            : 10px;

    &:global(.FULL) {
      background : green;
      //border     : 1px solid darken(green, 15%);
    }

    &:global(.HALF_FULL) {
      background : orange;
      //border     : 1px solid darken(orange, 15%);
    }

    &:global(.EMPTY) {
      background : red;
      //border     : 1px solid darken(red, 15%);
    }

    &:global(.INACTIVE) {
      background : $INACTIVE_COLOR;
      border     : 1px solid darken($INACTIVE_COLOR, 15%);
    }

    &:global(.UNKNOWN) {
      background : $UNKNOWN_COLOR;
      border     : 1px solid darken($UNKNOWN_COLOR, 15%);
    }
  }
}

.AddressContainer {
  align-items           : center;
  display               : grid;
  grid-template-columns : 1fr 1fr;
  justify-content       : center;
  justify-items         : center;
  margin                : 25px 0;
  width                 : 100%;

  @media (max-width : 676px) {
    gap                   : 15px;
    grid-template-columns : auto;
    justify-items         : start;
  }

  a {
    color           : black;
    text-decoration : none;
  }

  & a > div {
    align-items           : center;
    display               : grid;
    grid-template-columns : 50px 1fr;
    justify-items         : center;
  }

  img {
    width : 32px;
  }
}

.Socials {
  align-items           : center;
  display               : grid;
  grid-template-columns : repeat(auto-fit, minmax(150px, 1fr));
  justify-items         : center;
  margin                : 25px 0;
  width                 : 100%;

  @media (max-width : 676px) {
    gap                   : 15px;
    grid-template-columns : auto;
  }

  .Donation,
  .Instagram {
    background-color : #f8f8f8;
    border-radius    : 10px;

    font-size        : 18px;

    max-width        : 500px;
    min-width        : 150px;
    width            : 80%;
    padding          : 10px 5px;
    text-align       : center;

    img {
      width : 24px;
    }

    a {
      align-items     : center;
      color           : black;
      display         : flex;
      gap             : 15px;

      justify-content : center;
      text-decoration : unset;
    }
  }
}

.FridgeDescription {
  display    : grid;
  gap        : 15px;
  margin-top : 25px;
  width      : 80%;

  .FridgePhotos {
    display               : grid;
    gap                   : 25px;
    grid-template-columns : repeat(auto-fit, minmax(150px, 1fr));
    justify-items         : center;

    img {
      border        : 1px solid rgba(0, 0, 0, 0.2);
      border-radius : 10px;
      height        : 165px;
      max-width     : 150px;
      object-fit    : cover;
      width         : auto;
    }
  }

  &.SinglePhoto {
    display : grid;
    @media screen and (min-width : 768px) {
      grid-template-columns : 1fr 1fr;
    }

    img {
      align-self    : center;
      border        : 1px solid rgba(0, 0, 0, 0.2);
      border-radius : 10px;
      height        : 250px;
      justify-self  : center;
      max-width     : 250px;
    }
  }
}

.CheckButton {
  background     : #0076ff;
  border         : 0;
  border-radius  : 6.4px;

  color          : #ffffff;
  cursor         : pointer;
  font-size      : 18px;

  height         : 45px;
  letter-spacing : -0.36px;
  margin-top     : 15px;
  text-align     : center;

  width          : 80%;

  &:hover {
    background : darken(#0076ff, 5%);
  }
}

.SetRouteButton,
.EditButton {
  background     : white;
  border         : 1px solid rgba(0, 0, 0, 0.2);
  border-radius  : 6.4px;

  color          : black;
  cursor         : pointer;
  font-size      : 18px;

  height         : 45px;
  letter-spacing : -0.36px;
  margin-top     : 25px;
  text-align     : center;

  width          : 80%;

  &:hover {
    background : darken(white, 5%);
  }
}

.SetRouteButton {
  border          : 1px solid rgba(0, 0, 0, 0.2);

  display         : flex;
  align-items     : center;
  justify-content : center;
  gap             : 10px;

  img {
    width : 20px;
  }
}

.LastChecked {
  margin-top : 15px;
}

.ReportLink {
  margin-top : 15px;

  a {
    color     : black;
    font-size : 14px;
  }
}