.AppFooter {
  background-color : whitesmoke;
  border-top       : 1px solid rgba(0, 0, 0, 0.1);;
  margin-top       : 15px;
  overflow         : hidden;
}

.AppFooterContent {
  align-items : center;
  display     : grid;
  font-size   : 13px;
  margin      : auto;
  max-width   : 1000px;
  padding     : 15px 25px;

  @media screen and (min-width : 676px) {
    grid-template-columns : auto auto;
  }

  .Links {
    display               : grid;
    gap                   : 5px;
    grid-template-columns : repeat(auto-fit, minmax(175px, 1fr));
    justify-items         : center;

    @media screen and (min-width : 676px) {
      gap                   : 0;
      grid-template-columns : repeat(4, auto);
      max-width             : 400px;
    }

    a {
      color           : black;
      text-decoration : none;
    }
  }

  .CC {
    justify-self : center;
    margin-top   : 15px;
    @media screen and (min-width : 676px) {
      justify-self : end;
      margin-top   : 0;
    }
  }
}