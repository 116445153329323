.About {
  display       : grid;
  justify-items : center;
}

.TopAboutImg {
  align-items : center;
  display     : grid;
  width       : 100%;

  div:first-child {
    background   : linear-gradient(0deg, #fe6481 26%, #fe6481 84%);
    grid-area    : 1/1/1/1;
    height       : 100%;
    justify-self : start;
    width        : 50vw;
  }

  div:nth-child(2) {
    background   : linear-gradient(0deg, #f85f7c 28%, #f9617e 77%);
    grid-area    : 1/1/1/1;
    height       : 100%;
    justify-self : end;
    width        : 50vw;
  }

  img {
    grid-area    : 1/1/1/1;
    justify-self : center;
    max-height   : 200px;
    max-width    : 100vw;
  }
}

.AboutText {
  align-self   : center;
  color        : white;
  font-size    : 1em;
  grid-area    : 1/1/1/1;

  justify-self : center;
  @media (max-width : 768px) {
    font-size : 0.6em;
  }
}

.Content {
  margin-top     : 30px;
  max-width      : 1000px;
  padding-bottom : 100px;

  text-align     : justify;

  width          : 90vw;

  h2 {
    text-align : center;
  }
}

.Description {
  align-items   : center;
  display       : grid;
  justify-items : center;

}

.Images {
  display               : grid;
  gap                   : 25px;
  grid-template-columns : repeat(auto-fit, minmax(175px, 1fr));
  justify-items         : center;
  margin-top            : 25px;

  div {
    display    : grid;
    gap        : 15px;
    text-align : center;

    img {
      max-width : 175px;
    }
  }
}