.ShareHelp {
  display       : grid;
  justify-items : center;
  width         : 80vw;
  @media (min-width : 676px) {
    width : 650px;
  }

  h3 {
    color : #344563;
  }
}

.QRCode {
  height     : 250px;
  max-height : 25vh;
  max-width  : 50vw;
  padding    : 25px 0;
}

.Socials {
  display               : grid;
  grid-template-columns : repeat(3, 1fr);
  justify-content       : space-between;
  padding               : 25px 0;
  width                 : 100%;
  @media (max-width : 576px) {
    gap                   : 15px;
    grid-template-columns : auto;
    justify-content       : center;
    justify-items         : center;
  }

}

.SocialsCol {
  cursor        : pointer;
  display       : grid;
  gap           : 5px;
  justify-items : center;
  padding       : 15px 0;

  img {
    height : 32px;
    width  : auto;
  }
}

.PageLinkContainer { }

.Posters {
  display       : flex;
  position      : relative;
  gap           : 15px;
  justify-items : start;
  margin-bottom : 15px;

  a {
    color : black;
  }
}

.PageLinkInput {
  display  : grid;
  position : relative;

  input {
    background     : #f8f9fa;
    border         : 0;
    border-radius  : 6px;
    color          : #828e9e;
    font-size      : 18px;
    grid-area      : 1/1/1/1;
    height         : 50px;

    letter-spacing : 0;

    max-width      : 75vw;
    padding-left   : 15px;
    width          : 490px;
  }

  div {
    background     : #ffffff;
    border-radius  : 7.28px;
    box-shadow     : 0 11px 33px 0 rgba(112, 144, 176, 0.20);

    color          : #212121;

    font-size      : 16px;
    font-weight    : bold;
    letter-spacing : 0;

    padding        : 10px;
    position       : absolute;
    right          : -13px;
    text-align     : center;
    top            : -35px;
  }

  img {
    align-self   : center;
    cursor       : pointer;
    grid-area    : 1/1/1/1;
    height       : 25px;
    justify-self : end;
    margin-right : 15px;
  }
}