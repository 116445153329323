.App {

}

.AppHeaderWrapper {
  margin    : 0 auto;
  max-width : 1000px;
}

.AppContent {
  min-height : calc(100vh - 100px - 50px - 15px);
}

.AppLink {
  color : #61dafb;
}
