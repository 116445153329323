.Page404 {
  display       : grid;
  justify-items : center;
}

.TopFridgeImg {
  height : 200px;
  width  : 100%;
}

.Content {
  width                 : 100%;
  max-width             : 1000px;
  margin-top            : 45px;

  padding-bottom: 100px;
}