.FridgeMap {
  align-content   : center;
  align-items     : center;
  display         : grid;
  justify-content : center;
  justify-items   : center;
  position        : relative;
}

.Map {
  border-radius : 15px;
  grid-area     : 1/1/1/1;
  height        : calc(98vh - 100px);
  overflow      : hidden;
  width         : 98vw;
}

.ToggleSearchNearest {
  background     : #ffffff;
  box-shadow     : 0 0 10px 0 rgba(165, 165, 165, 0.5);
  cursor         : pointer;
  display        : grid;
  font-family    : "Bozon-Bold", sans-serif;
  font-size      : 16px;
  gap            : 5px;
  left           : 215px;
  letter-spacing : 0;
  padding        : 10px 15px;
  position       : absolute;
  top            : 10px;
  z-index        : 2;
}

.SearchNearest {
  background         : #ffffff;
  border-radius      : 16px;
  box-shadow         : 0 0 103px 0 rgba(165, 165, 165, 0.5);
  display            : grid;
  gap                : 5px;
  grid-template-rows : 30px 25px 50px;
  left               : 75px;
  padding            : 25px;
  position           : absolute;
  top                : 75px;
  width              : 200px;
  z-index            : 2;

  .SearchNearestMinimize {
    cursor   : pointer;
    position : absolute;
    right    : 15px;
    top      : 15px;
  }

  .SearchTitle {
    color          : #344563;
    font-family    : "Bozon Bold", sans-serif;
    font-size      : 25px;
    letter-spacing : 0;
  }

  .SearchSubTitle {
    color          : #5e6c84;
    font-family    : "Bozon Regular", sans-serif;
    font-size      : 15px;
    letter-spacing : 0;
  }

  .SearchFilter {
    display : flex;
    gap     : 5px;

    .SearchFilterButton {
      background    : #f8f8f8;
      border        : 1px solid #f8f8f8;
      border-radius : 10px;
      cursor        : pointer;
      font-family   : 'Bozon-Bold', sans-serif;
      font-size     : 14px;
      height        : 36px;
      line-height   : 34px;
      text-align    : center;

      width         : 100px;

      &:global.active {
        background : #0076ff;
        color      : white;
      }

      &:hover {
        border : 1px solid darken(rgba(0, 0, 0, 0.2), 5%);
      }
    }
  }

  .SearchButton {
    align-items           : center;
    border                : 1px solid rgba(177, 182, 185, 0.75);
    border-radius         : 6px;
    cursor                : pointer;
    display               : grid;
    font-family           : 'Bozon-Bold', sans-serif;
    font-size             : 14px;
    grid-template-columns : 40px 130px;
    height                : 36px;
    justify-content       : center;

    &:hover {
      border : 1px solid rgba(177, 182, 185, 1);
    }

    img {
      margin-left : 15px;
      margin-top  : 5px;
      width       : 15px;
    }
  }
}