.SetFridge {
  display       : grid;
  justify-items : center;
}

.FormContainer {
  background    : white;
  border-radius : 16px;
  box-shadow    : 0 0 103px 0 rgba(165, 165, 165, 0.17);
  display       : grid;
  gap           : 15px;
  justify-items : center;

  margin-bottom : 50px;
  max-width     : 95vw;
  width         : 650px;

  h2 {
    padding-top : 10px;
  }
}

.FormRow {
  display   : grid;
  font-size : 15px;
  gap       : 10px;
  width     : 100%;
}

.FormRowLabel {
  color          : #000000;
  font-size      : 15px;
  justify-self   : start;

  letter-spacing : 0;
  margin-left    : 5%;
  width          : 100%;
}

.FormRowInput {
  background    : #f8f8f8;
  border        : 0;
  border-radius : 5px;
  height        : 40px;
  justify-self  : center;
  padding-left  : 15px;
  width         : 90%;
}

.FormRowTextarea {
  background    : #f8f8f8;
  border        : 0;
  border-radius : 5px;
  justify-self  : center;
  padding-left  : 15px;
  width         : 90%;
  padding-top   : 15px;
  resize        : vertical;
  min-height    : 30px;
}

.FormRowSelect {
  -moz-appearance       : none;
  -webkit-appearance    : none;
  background            : #f8f8f8;
  background-image      : url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-position-x : 98%;
  background-position-y : 50%;
  background-repeat     : no-repeat;

  border                : 0;
  border-radius         : 5px;
  height                : 40px;
  justify-self          : center;
  padding-left          : 15px;
  width                 : 92.5%;
}

.ButtonsRow {
  align-items           : center;
  display               : grid;
  grid-template-columns : 1fr 1fr;
  height                : 100px;
  justify-items         : center;
  width                 : 100%;
}

.ResetButton {
  align-items    : center;
  background     : white;
  border         : 1px solid #b1b6b9;

  border-radius  : 6.4px;
  display        : grid;
  font-size      : 13px;

  height         : 45px;
  letter-spacing : -0.36px;
  max-width      : 40vw;

  text-align     : center;
  width          : 215px;

  &:hover {
    cursor : pointer;
  }
}

.SubmitButton {
  align-items    : center;
  background     : #0076ff;
  border         : 0;
  border-radius  : 6.4px;
  color          : #ffffff;
  display        : grid;

  font-size      : 13px;
  height         : 45px;
  letter-spacing : -0.36px;
  max-width      : 40vw;

  text-align     : center;
  width          : 215px;

  &:hover {
    cursor : pointer;
  }
}

.ImageRow {
  display   : grid;
  font-size : 15px;
  gap       : 10px;
  width     : 100%;
}

.ImageInput {
  align-content : center;
  display       : grid;

  div {
    grid-area      : 1/1/1/1;
    pointer-events : none;
    width          : 100%;
    z-index        : 1;
  }

  input {
    grid-area      : 1/1/1/1;
    height         : 100%;
    opacity        : 0;
    pointer-events : auto;
    width          : 100%;
    z-index        : 0;
  }
}

.InstagramPhotos {
  align-items           : center;
  display               : grid;
  gap                   : 10px;
  grid-template-columns : repeat(auto-fit, minmax(150px, 1fr));
  justify-items         : center;
  width                 : 100%;

  .Photo {
    :not(.active) {
      filter : opacity(0.5);
    }

    .active {
      filter  : opacity(1);
      outline : 2px solid rgba(0, 0, 0, 0.5);
    }

    img {
      cursor     : pointer;
      height     : 160px;
      object-fit : cover;
      width      : 120px;

      &:hover {
        outline : 2px solid rgba(0, 0, 0, 0.5);
      }
    }
  }
}