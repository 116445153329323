.IntagramAuth {
  display       : grid;
  justify-items : center;
  text-align    : center;
}

.TopFridgeImg {
  height : 200px;
  width  : 100%;
}

.Content {
  margin-top     : 45px;
  max-width      : 1000px;
  padding-bottom : 100px;

  width          : 100%;
}