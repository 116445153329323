@import '../globalVariables';

.FridgeList {
  display       : grid;
  justify-items : center;
}

.TopFridgeList {
  align-items : center;
  display     : grid;
  width       : 100%;

  div:first-child {
    background   : linear-gradient(0deg, #c32a49 26%, #c72f4d 84%);
    grid-area    : 1/1/1/1;
    height       : 100%;
    justify-self : start;
    width        : 50vw;
  }

  div:nth-child(2) {
    background   : linear-gradient(0deg, #fe6580 28%, #f25a75 77%);
    grid-area    : 1/1/1/1;
    height       : 100%;
    justify-self : end;
    width        : 50vw;
  }

  img {
    grid-area    : 1/1/1/1;
    justify-self : center;
    max-height   : 200px;
    max-width    : 100vw;
  }
}

.FridgeListText {
  align-self   : center;
  color        : white;
  font-size    : 1em;
  grid-area    : 1/1/1/1;
  justify-self : center;
  @media (max-width : 768px) {
    font-size : 0.6em;
  }
}

.Content {
  display               : grid;
  grid-template-columns : 164px auto;
  margin-top            : 45px;
  max-width             : 1000px;

  overflow              : auto;
  padding-bottom        : 100px;

  width                 : 90vw;

  @media (max-width : 1023px) {
    grid-template-columns : 1fr;
    margin-top            : 15px;
  }
}

.TableMenu {
  border                : 3px solid #f4f5f7;
  border-radius         : 16px;
  display               : grid;
  font-size             : 12px;
  gap                   : 15px;
  grid-template-columns : 75px auto;
  justify-content       : center;
  width                 : 250px;

  @media (min-width : 1024px) {
    align-content         : center;
    grid-template-columns : 1fr;
    height                : 100px;
    justify-content       : center;
    width                 : 164px;
  }

  .TableMenuRow {
    align-items           : center;
    cursor                : pointer;
    display               : grid;
    grid-template-columns : 25px auto;
    padding               : 10px;

    @media (min-width : 1023px) {
      margin-left : 25px;
      padding     : 0;
    }

    &:hover {
      color : gray;
    }

    img {
      height : 15px;
      width  : 15px;
    }
  }
}


.Table {
  display    : grid;
  margin-top : 15px;

  @media (min-width : 1024px) {
    margin : 0 50px;
  }

  .TableHeader,
  .TableRow {
    cursor                : pointer;
    display               : grid;
    gap                   : 15px;
    grid-template-columns : 4fr 1fr 1fr;

    &:global(.LOGGED_IN) {
      cursor                : default;
      grid-template-columns : 4fr 1fr 1fr 1fr;
    }
  }

  .TableHeader {
    cursor         : default;
    font-size      : 14px;
    letter-spacing : 0;
    margin-bottom  : 15px;
  }

  .TableBody {
    display : grid;
    row-gap : 15px;

    .TableRow {
      align-items    : center;
      display        : grid;

      font-size      : 14px;
      letter-spacing : 0;

      :first-child {
        align-items : center;
        display     : flex;
        gap         : 5px;
      }

      img {
        height : 64px;
        width  : 64px;
      }

      &:hover {
        background    : #f8f8f8;
        border-radius : 100px;
      }

      .TableRowStatus {
        background      : #abf5d1;
        border          : 1px solid #57d9a3;
        border-radius   : 12px;
        display         : grid;
        font-size       : 12px;
        height          : 24px;
        justify-content : center;

        text-transform  : capitalize;
        width           : 70px;

        &:global(.INACTIVE) {
          background-color : $INACTIVE_COLOR;
          border           : 1px solid darken($INACTIVE_COLOR, 10%);
        }

        &:global(.UNKNOWN) {
          background-color : $UNKNOWN_COLOR;
          border           : 1px solid darken($UNKNOWN_COLOR, 10%);
        }
      }

      .TableRowOptions {
        align-items : center;
        display     : flex;
        gap         : 10px;

        img {
          cursor : pointer;
          height : 20px;
          width  : 20px;
        }
      }
    }
  }
}

.RemoveModal {
  display       : grid;
  justify-items : end;

  button {
    align-self     : end;
    background     : #ad1f1f;
    border         : 0;
    border-radius  : 6.4px;

    color          : #ffffff;
    cursor         : pointer;
    font-size      : 18px;

    height         : 45px;
    letter-spacing : -0.36px;
    margin-top     : 20px;
    text-align     : center;

    width          : 200px;

    &:hover {
      background : darken(#ad1f1f, 10%);
    }
  }
}