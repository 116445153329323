@import '~animate.css';

@font-face {
  font-family : 'Bozon Regular';
  src         : url('assets/fonts/Bozon Regular/Bozon-Regular.eot'); /* IE9 Compat Modes */
  src         : url('assets/fonts/Bozon Regular/Bozon-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Bozon Regular/Bozon-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('assets/fonts/Bozon Regular/Bozon-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Bozon Regular/Bozon-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family : 'Bozon Bold';
  src         : url('assets/fonts/Bozon Bold/Bozon-Bold.eot'); /* IE9 Compat Modes */
  src         : url('assets/fonts/Bozon Bold/Bozon-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Bozon Bold/Bozon-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('assets/fonts/Bozon Bold/Bozon-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Bozon Bold/Bozon-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

body {
  font-family             : 'Bozon Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing  : antialiased;
  margin                  : 0;
  -moz-osx-font-smoothing : grayscale;
  overflow-x              : hidden;
}

strong, b {
  font-family : 'Bozon Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}