.AppHeader {
  align-items      : center;
  background-color : #ffffff;
  display          : grid;
  height           : 100px;
  margin           : auto;
  max-width        : 1000px;
  width            : 90vw;
}

.AppLogo {
  height : 60px;
  width  : auto;
}

.AppNav {
  align-items           : center;
  display               : grid;
  gap                   : 1em;
  grid-template-columns : auto auto 250px;
  justify-content       : space-between;

  *:hover:before {
    background    : red;
    border-radius : 100%;
    content       : "";
    height        : 15px;
    left          : calc(50% - 8px);
    position      : absolute;
    top           : -15px;
    width         : 15px;
  }

  @media (max-width : 767px) {
    display : none;
  }
}

.AppNavRegularLink {
  align-items           : center;
  display               : grid;
  grid-template-columns : repeat(3, minmax(75px, 120px));
  justify-content       : end;
  justify-items         : end;
  margin-left           : 35px;
}

.AppNavShareAndAdd {
  align-items           : center;
  align-self            : center;
  display               : grid;
  gap                   : 50px;
  grid-template-columns : auto auto;
  justify-self          : end;
  @media (max-width : 900px) {
    gap : 15px;
  }
}

.MobileNav {
  display               : grid;
  grid-template-columns : auto auto;
  justify-content       : space-between;
  justify-self          : end;
  width                 : 100%;
  @media (min-width : 768px) {
    display : none;
  }
}

.MobileNavLinks {
  background-color : white;
  border-bottom    : 1px solid rgba(0, 0, 0, 0.2);
  border-top       : 1px solid rgba(0, 0, 0, 0.2);
  display          : grid;
  gap              : 15px;
  left             : 0;
  padding          : 25px 0;
  position         : absolute;
  top              : 100px;

  width            : 100vw;
  z-index          : 2;
}

.AppNavMobileLink {
  color           : #474747;
  cursor          : pointer;
  font-size       : 17px;
  letter-spacing  : 0;
  text-align      : center;
  text-decoration : none;
}

.AppNavLink {
  color           : #474747;
  cursor          : pointer;
  font-size       : 17px;
  letter-spacing  : 0;
  text-align      : center;
  text-decoration : none;

  &:global:not(:hover).active {
    display        : inline-block;
    position       : relative;
    vertical-align : top;

    &:not(:hover)::before {
      background    : black;
      border-radius : 100%;
      bottom        : -15px;
      content       : '';
      height        : 4px;
      left          : 50%;
      position      : absolute;
      transform     : translateX(-50%);
      width         : 4px;
    }
  }
}

.AddFridgeButton {
  align-items           : center;
  background            : #0076ff;
  border-radius         : 6.4px;
  color                 : #ffffff;

  cursor                : pointer;
  display               : grid;
  font-size             : 13px;
  grid-template-columns : 50px auto;

  height                : 45px;
  justify-items         : start;
  letter-spacing        : -0.36px;
  text-align            : center;

  width                 : 140px;

  &:hover {
    background : darken(#0076ff, 10%);
  }

  img {
    height       : 25px;
    justify-self : center;
  }
}