.Login {
  display         : grid;
  justify-content : center;
}

.TopLoginImg {
  height : 200px;
  width  : 100%;
}

.Form {
  align-items     : center;
  display         : grid;
  gap             : 15px;
  justify-content : center;
  justify-items   : center;
  max-width       : 95vw;
  width           : 500px;

  label {
    justify-self : start;
  }

  input {
    background     : #f8f9fa;
    border         : 0;
    border-radius  : 6px;
    color          : #828e9e;
    font-size      : 18px;
    height         : 50px;

    letter-spacing : 0;
    padding-left   : 15px;
    width          : 300px;
  }

  .InstagramConnectButton,
  .InstagramDisconnectButton,
  .LinkButton,
  .AuthButton {
    align-content   : center;
    align-items     : center;
    background      : #0076ff;
    border          : 0;
    border-radius   : 6.4px;
    color           : #ffffff;
    cursor          : pointer;
    display         : flex;
    font-size       : 18px;
    gap             : 5px;
    height          : 45px;
    justify-content : center;
    letter-spacing  : -0.36px;
    margin-top      : 15px;
    text-align      : center;
    width           : 300px;

    &:hover {
      background : darken(#0076ff, 10%);
    }

    img {
      height : 20px;
      width  : 20px;
    }
  }

  .InstagramConnectButton {
    background : #fe244e;
    color      : white;

    &:hover {
      background : darken(#fe244e, 10%);
    }
  }

  .InstagramDisconnectButton {
    background : darkred;
    color      : white;

    &:hover {
      background : darken(darkred, 10%);
    }
  }

  .LinkButton {
    background      : lightgrey;
    color           : black;
    line-height     : 45px;
    text-decoration : none;

    &:hover {
      background : darken(lightgrey, 10%);
    }
  }

  .ForgotPasswordButton {
    background : transparent;
    border     : none;
    color      : #0076ff;
    cursor     : pointer;
    font-size  : 16px;

    &:hover {
      color : darken(#0076ff, 10%);
    }
  }
}

.Content {
  align-items    : center;
  display        : grid;
  justify-items  : center;
  margin-top     : 30px;

  max-width      : 1000px;
  padding-bottom : 50px;
  width          : 90vw;
}